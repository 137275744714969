require('./bootstrap');
window.$ = window.jQuery = require('jquery');
require('cropperjs');
require('jquery-cropper');
window.bootstrap = require('bootstrap');
require('select2');
require('jquery-ui-dist/jquery-ui');
window.Split = require('split.js');
require('jquery-resizable');
require('jquery-connections');
$(document).ready(function () {
    $('.select2-select').select2( {
//        theme: 'bootstrap-5'
    });    
});

window.Resumable = require('resumablejs');
require('./resumable-custom');

$(document).ready(function () {
    $('.bs-select').selectpicker();
});

function numberOnly(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
         // Allow: Ctrl/cmd+A
        (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
         // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
             // let it happen, don't do anything
             return;
    }

    if (e.keyCode == 86 && (e.ctrlKey === true || e.metaKey === true)) {
        return;
    }

    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }
}

function pasteNumberOnly(event) {
    var paste = event.clipboardData.getData("Text");
    if (paste != paste.replace(/[^0-9]/g, '')) {
        event.preventDefault();
    }
    return;
}